<template>
  <div>
    <List
      :fields="fields"
      modelName="Vehículos"
      create_url="core_vehicle_create"
      backend_url="core/vehicle"
      model="core_vehicle"
      :is_paginated="true"
    ></List>
  </div>
</template>

<script>
import List from "@/view/pages/crud/List";

export default {
  name: "VehicleList",
  components: { List },
  data() {
    return {
      fields: [
        { key: "plate", label: "Placa" },
        { key: "created_at", label: "Creado el" },
        { key: "updated_at", label: "Modificado el" },
        { key: "is_active", label: "Activar" },
        { key: "actions", label: "" },
      ],
    };
  },
};
</script>

<style scoped></style>
